import React, {Component} from 'react'
import {Link} from 'gatsby'
import {navbar, navbarNav, logo, navLink, navItem, activeNavLink, linkIcon, linkText, logoText} from './navbar.module.scss'
import InlineSVG from './inlineSvg'

const themeMap = {
    dark: {
        name: 'Dark',
        next: 'light',
        icon: 'moon'
    },
    light: {
        name: 'Light',
        next: 'solar',
        icon: 'sun'
    },
    solar: {
        name: 'Solarized',
        next: 'dark',
        icon: 'sunglasses'
    }
}

class Navbar extends Component {
    constructor(props) {
        super(props)

        this.state = {currentTheme: 'dark', loadedTheme: false}
        this.toggleTheme = this.toggleTheme.bind(this)
        this.bodyClass = undefined
    }

    componentDidMount() {
        let theme = localStorage.getItem('theme') 
        if (!theme) {
            theme = 'dark'
            localStorage.setItem('theme', theme)
        }
        this.bodyClass = document.body.classList;
        this.bodyClass.add(theme);
        this.setState({'currentTheme': theme, 'loadedTheme': true})
    }

    toggleTheme() {
        const next = themeMap[this.state.currentTheme].next
    
        this.bodyClass.replace(this.state.currentTheme, next)
        localStorage.setItem('theme', next)
        this.setState({'currentTheme': next})
    }

    render() {
        if (!this.state.loadedTheme) {
            return (
                <div></div>
            )
        }

        return (
            <nav className={navbar}>
                <ul className={navbarNav}>
                    <li className={logo}>
                        <Link className={navLink} activeClassName={activeNavLink} to="/">
                            <span className={`${linkText} ${logoText}`}>NTW</span>
                            <InlineSVG tag="doubleRightAngle" className={linkIcon}/>
                        </Link>
                    </li>
                    <li className={navItem}>
                        <Link className={navLink} activeClassName={activeNavLink} to="/contact/">
                            <InlineSVG tag="addressCard" className={linkIcon} />
                            <span className={linkText}>Contact</span>
                        </Link>
                    </li>
                    <li className={navItem}>
                        <button className={navLink} onClick={this.toggleTheme}>
                            {Object.keys(themeMap).map((theme) => (
                                <InlineSVG key={theme} tag={themeMap[theme].icon} className={`${linkIcon} ${this.state.currentTheme !== theme ? 'hidden' : ''}`} />
                            ))}
                            <span className={linkText}>Theme</span>
                        </button>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default Navbar