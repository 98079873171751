import React, {Component} from 'react'
import FeatherIcon from '../../static/img/inline/feather-alt-solid.svg'
import ExpandIcon from '../../static/img/inline/angle-double-right-solid.svg'
import MoonIcon from '../../static/img/inline/moon.svg'
import SunIcon from '../../static/img/inline/sun-solid.svg'
import SunglassesIcon from '../../static/img/inline/sunglasses.svg'
import AddressCardIcon from '../../static/img/inline/address-card-solid.svg'
import LinkedInIcon from '../../static/img/inline/linkedin.svg'
import GithubIcon from '../../static/img/inline/github.svg'
import GitlabIcon from '../../static/img/inline/gitlab-tile.svg'
import TwitterIcon from '../../static/img/inline/twitter.svg'

class InlineSVG extends Component {
    mappings = {
        feather: FeatherIcon,
        doubleRightAngle: ExpandIcon,
        moon: MoonIcon,
        sun: SunIcon,
        sunglasses: SunglassesIcon,
        addressCard: AddressCardIcon,
        linkedIn: LinkedInIcon,
        github: GithubIcon,
        gitlab: GitlabIcon,
        twitter: TwitterIcon,
    }

    render() {
        const TagName = this.mappings[this.props.tag || 'sunglasses'];
        return <TagName className={this.props.className} style={this.props.style}/>
    }
}

export default InlineSVG
