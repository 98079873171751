import React from 'react'
import Header from './header'
import Footer from './footer'
import Navbar from './navbar'
import '../styles/index.scss'
import {rootContainer, container, content} from './layout.module.scss'

const Layout = (props) => {
    return (
        <div className={rootContainer}>
            <Navbar />
            <div className={container}>
                <Header />
                <div className={content}>
                    {props.children}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Layout