import React from 'react'
import {Link} from 'gatsby'
import {header, navList, navItem, logo, navLink, activeNavLink} from './header.module.scss'

const Header = () => {
    return (
        <header className={header}>
            <nav>
                <ul className={navList}>
                    <li className={`${navItem} margin-right-auto`}>
                        <Link className={logo} to="/"></Link>
                    </li>
                    <li className={navItem}>
                        <Link className={navLink} activeClassName={activeNavLink} to="/contact/">Contact</Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header