import React from 'react'
import {Helmet} from 'react-helmet'
import {graphql, useStaticQuery} from 'gatsby'

const Head = ({url, title, description, image, isBlogPost}) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title,
                    description,
                    blogUrl,
                    url,
                    image,
                    twitter
                }
            }
        }
    `)
    const urlMeta = isBlogPost ? data.site.siteMetadata.blogUrl + url : url ? data.site.siteMetadata.url + url : data.site.siteMetadata.url
    const titleMeta = title + ' | ' + data.site.siteMetadata.title
    const descriptionMeta = description || data.site.siteMetadata.description
    const imageMeta = isBlogPost ? data.site.siteMetadata.url + image : image || data.site.siteMetadata.image

    return (
        <Helmet title={titleMeta} defer={false}>
            <meta name="description" content={descriptionMeta} />
            <meta name="image" content={imageMeta} />
            <link rel="canonical" href={urlMeta} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={urlMeta} />
            <meta property="og:type" content={isBlogPost ? 'article': 'website'} />
            <meta property="og:title" content={titleMeta} />
            <meta property="og:description" content={descriptionMeta} />
            <meta property="og:image" content={imageMeta} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content={data.site.siteMetadata.twitter} />
            <meta name="twitter:title" content={titleMeta} />
            <meta name="twitter:description" content={descriptionMeta} />
            <meta name="twitter:image" content={imageMeta} />
        </Helmet>
    )
}

Head.defaultProps = {
    url: '',
    title: '',
    description: '',
    image: null,
    isBlogPost: false
}

export default Head