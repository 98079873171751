import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {footer} from './footer.module.scss'

const Footer = () => {
    const author = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `).site.siteMetadata.author

    return (
        <footer className={footer}>
            <p>{author} © 2024</p>
        </footer>
    )
}

export default Footer